import classNames from 'classnames'
import React, { type FC } from 'react'
import { Link } from 'react-router-dom'
import styles from './CYCSLink.module.scss'
import { HashLink } from 'react-router-hash-link'

interface CYCSLinkProps {
  to?: string
  href?: string
  underline?: boolean
  hashLink?: boolean
  flex?: boolean;
  children: React.ReactNode
  variant?: 'primary' | 'white' | 'default'
  className?: string
  [key: string]: unknown
}

const CYCSLink: FC<CYCSLinkProps> = ({
  to,
  href,
  underline = true,
  variant = 'primary',
  hashLink = false,
  flex = false,
  children,
  className,
  ...rest
}) => {
  const classes = classNames(
    styles.link,
    styles[variant],
    {
      [styles.noUnderline]: !underline,
      [styles.flex]: flex,
    },
    className
  )

  if(hashLink) {
    return (
      <HashLink className={classes} to={to!} {...rest}>
        {children}
      </HashLink>
    )
  }

  if (to) {
    return (
      <Link className={classes} to={to} {...rest}>
        {children}
      </Link>
    )
  }

  if (href) {
    return (
      <a className={classes} href={href} {...rest}>
        {children}
      </a>
    )
  }

  return (
    <button className={classNames(styles.cleanButton, classes)} {...rest}>
      {children}
    </button>
  )
}

export default CYCSLink
