import React, {
  useEffect,
  useState,
  useReducer,
  type ReactNode,
  useCallback,
} from 'react'

import { useCookies } from 'react-cookie'
import { Footer } from '../components/organisms/Footer'
import { Header } from '../components/organisms/Header'
import UiContext from '../shared/context/UiContext'
import type { State } from '../shared/interface/uiInterface'
import { UiReducer } from '../shared/reducers/UiReducer'
import { CookieBanner } from '../components/organisms/CookieBanner'
import { SkipToContent } from '../components/atoms/SkipToContent'

// To match NCSC website
const consentCookieName = 'google consent'
const actionedConsentCookieName = 'consent cookie'

interface IProps {
  children: ReactNode
}

export const LayoutTemplate: React.FC<IProps> = ({ children }: IProps) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    consentCookieName,
    actionedConsentCookieName,
  ])
  const cookieConsent = cookies[consentCookieName] as string
  const cookieConsentActioned = cookies[actionedConsentCookieName] as boolean
  const [showCookieWidget, setShowCookieWidget] = useState(true)

  const setGtagConsent = (consent: string) => {
    const consentValue = consent ? 'granted' : 'denied'
    gtag('consent', 'update', {
      analytics_storage: consentValue,
      ad_storage: consentValue,
    })
  }

  const domain = window.location.hostname.endsWith('.ncsc.gov.uk')
    ? '.ncsc.gov.uk'
    : window.location.hostname

  const manualRemoveCookie = (cookieName: string) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
  }

  const manualSetCookie = (
    name: string,
    value: string,
    path = '/',
    domain?: string
  ) => {
    document.cookie = `${name}=${value}; path=${path}; ${domain ? `domain=${domain};` : ''}`
  }

  const acceptCookie = useCallback(() => {
    manualSetCookie(consentCookieName, 'On', '/', domain)
    manualSetCookie(actionedConsentCookieName, 'true', '/', domain)
    window.dispatchEvent(new CustomEvent('cookiesAccepted'))
    setShowCookieWidget(false)
  }, [domain, setCookie])

  const declineCookie = useCallback(() => {
    manualRemoveCookie(consentCookieName)
    manualSetCookie(actionedConsentCookieName, 'true', '/', domain)
    window.dispatchEvent(new CustomEvent('cookiesDeclined'))
    setShowCookieWidget(false)
  }, [domain, removeCookie, setCookie])

  useEffect(() => {
    if (cookieConsent !== undefined) {
      setGtagConsent(cookieConsent)
    }
  }, [cookieConsent])

  useEffect(() => {
    if (cookieConsentActioned) {
      setShowCookieWidget(false)
    }
  }, [cookieConsentActioned])

  const defaultUIContext: State = {
    isPopupOpened: false,
    isLoading: false,
    isLoadingSpinner: false,
    isStickyPopupOpened: false,
    viewExtendedChecks: false,
  }
  const [state, dispatch] = useReducer(UiReducer, defaultUIContext)
  return (
    <UiContext.Provider value={{ state, dispatch }}>
      <CookieBanner
        acceptCookie={acceptCookie}
        declineCookie={declineCookie}
        showCookieWidget={showCookieWidget}
      />
      <SkipToContent />
      <Header />
      <main>{children}</main>
      <Footer />
    </UiContext.Provider>
  )
}

export default LayoutTemplate
