// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.N0yGtPHTNamJyaf6GjNQ{position:relative;display:block;margin:0 auto;width:var(--s-8);height:var(--s-8)}.N0yGtPHTNamJyaf6GjNQ>svg{width:100%;height:100%;animation:kGV8J_i0G3g7QY22i_x8 1.5s linear infinite}@media(prefers-reduced-motion){.N0yGtPHTNamJyaf6GjNQ::before{animation:kGV8J_i0G3g7QY22i_x8 5.5s linear infinite}}@keyframes kGV8J_i0G3g7QY22i_x8{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}`, "",{"version":3,"sources":["webpack://./src/components/atoms/Spinner/spinner.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,aAAA,CACA,aAAA,CACA,gBAAA,CACA,iBAAA,CAEA,0BACE,UAAA,CACA,WAAA,CACA,mDAAA,CAIJ,+BACE,8BACE,mDAAA,CAAA,CAIJ,gCACE,GACE,sBAAA,CAGF,KACE,wBAAA,CAAA","sourcesContent":[".spinner{position:relative;display:block;margin:0 auto;width:var(--s-8);height:var(--s-8)}.spinner>svg{width:100%;height:100%;animation:rotator 1.5s linear infinite}@media(prefers-reduced-motion){.spinner::before{animation:rotator 5.5s linear infinite}}@keyframes rotator{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `N0yGtPHTNamJyaf6GjNQ`,
	"rotator": `kGV8J_i0G3g7QY22i_x8`
};
export default ___CSS_LOADER_EXPORT___;
