// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cYCodmGCYzb61cHbR29G{padding-block:var(--s-3);height:90px;background:radial-gradient(ellipse at 50% bottom, var(--c-ncsc-lighter-blue) 0, var(--c-ncsc-blue) 60%);padding-inline:var(--sm-margin)}@media(width >= 768px){.cYCodmGCYzb61cHbR29G{padding-inline:var(--md-margin)}}.o8zgWp1wEfOIcaWdcyQN{width:100%;height:100%;display:flex;align-items:center;max-width:var(--max-md);margin-inline:auto}.AU5Ag1HW65tD_UWmtNvt{display:flex;flex:1;height:100%;align-items:center;gap:var(--s-4);justify-content:flex-start}.DD3RAT4Wlb1rwnK0nQ1D{display:flex;flex:2;gap:var(--s-4);justify-content:flex-end}.OREWxniy_paUgPEWL6w2{margin-right:var(--s-4)}@media(width < 768px){.OREWxniy_paUgPEWL6w2{width:170px}}`, "",{"version":3,"sources":["webpack://./src/components/organisms/Header/Header.module.scss"],"names":[],"mappings":"AAAA,sBACI,wBAAA,CAEA,WAAA,CAEA,uGAAA,CAEA,+BAAA,CAEA,uBATJ,sBAUQ,+BAAA,CAAA,CAIR,sBACI,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CAEA,uBAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,MAAA,CACA,WAAA,CACA,kBAAA,CACA,cAAA,CACA,0BAAA,CAGJ,sBACI,YAAA,CACA,MAAA,CACA,cAAA,CACA,wBAAA,CAGJ,sBACI,uBAAA,CAEA,sBAHJ,sBAIQ,WAAA,CAAA","sourcesContent":[".header{padding-block:var(--s-3);height:90px;background:radial-gradient(ellipse at 50% bottom, var(--c-ncsc-lighter-blue) 0, var(--c-ncsc-blue) 60%);padding-inline:var(--sm-margin)}@media(width >= 768px){.header{padding-inline:var(--md-margin)}}.headerContent{width:100%;height:100%;display:flex;align-items:center;max-width:var(--max-md);margin-inline:auto}.leftContent{display:flex;flex:1;height:100%;align-items:center;gap:var(--s-4);justify-content:flex-start}.rightContent{display:flex;flex:2;gap:var(--s-4);justify-content:flex-end}.logo{margin-right:var(--s-4)}@media(width < 768px){.logo{width:170px}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `cYCodmGCYzb61cHbR29G`,
	"headerContent": `o8zgWp1wEfOIcaWdcyQN`,
	"leftContent": `AU5Ag1HW65tD_UWmtNvt`,
	"rightContent": `DD3RAT4Wlb1rwnK0nQ1D`,
	"logo": `OREWxniy_paUgPEWL6w2`
};
export default ___CSS_LOADER_EXPORT___;
