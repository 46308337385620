import React, { type FC } from 'react'

import styles from './CookieBanner.module.scss'
import { CYCSLink } from '../../atoms/CYCSLink'
import { CYCSButton } from '../../atoms/CYCSButton'

export interface CookieBannerProps {
  acceptCookie: () => void
  declineCookie: () => void
  showCookieWidget: boolean
}

const CookieBanner: FC<CookieBannerProps> = ({
  acceptCookie,
  declineCookie,
  showCookieWidget,
}) => {
  if (!showCookieWidget) {
    return <></>
  }

  return (
    <section
      className={styles.cookieBanner}
      data-nosnippet={true}
      aria-label="Cookies on Check Your Cyber Security"
      data-cy="cookie-banner"
    >
      <h1 className={styles.cookieBannerHeading}>
        Cookies on Check Your Cyber Security
      </h1>
      <div>
        <p>We use some essential cookies to make this service work.</p>
        <p>
          We’d also like to use analytics cookies so we can understand how you
          use the service and make improvements.
        </p>
      </div>
      <div className={styles.cookieBannerButtons}>
        <CYCSButton
          value="accept"
          type="button"
          name="cookies"
          onClick={acceptCookie}
          data-cy="accept-cookies"
          variant={'secondary'}
        >
          Accept analytics cookies
        </CYCSButton>
        <CYCSButton
          value="reject"
          type="button"
          name="cookies"
          onClick={declineCookie}
          data-cy="reject-cookies"
          variant={'secondary'}
        >
          Reject analytics cookies
        </CYCSButton>
      </div>
      <div>
        <CYCSLink
          href="https://www.ncsc.gov.uk/section/about-this-website/cookies"
          target="_blank"
          rel="noreferrer"
          underline={false}
        >
          Manage cookies (opens new tab)
        </CYCSLink>
      </div>
    </section>
  )
}

export default CookieBanner
