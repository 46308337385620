import classNames from 'classnames'
import React, { type FC } from 'react'
import styles from './CYCSButton.module.scss'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

interface CYCSButtonProps {
  children?: React.ReactNode
  hashLink?: boolean
  to?: string
  href?: string
  variant?: 'primary' | 'secondary' | 'tertiary' | 'white'
  size?: 'small' | 'medium' | 'large'
  className?: string
  [key: string]: unknown
}

const CYCSButton: FC<CYCSButtonProps> = ({
  children,
  to,
  href,
  variant = 'secondary',
  size = 'medium',
  hashLink = false,
  className,
  ...props
}) => {
  const classes = classNames(
    styles.button,
    styles[variant],
    styles[size],
    className
  )

  if (hashLink) {
    return (
      <HashLink className={classes} to={to!} {...props}>
        {children}
      </HashLink>
    )
  }

  if (to) {
    return (
      <Link className={classes} to={to} {...props}>
        {children}
      </Link>
    )
  }

  if (href) {
    return (
      <a className={classes} href={href} {...props}>
        {children}
      </a>
    )
  }

  return (
    <button className={classes} {...props}>
      {children}
    </button>
  )
}

export default CYCSButton
