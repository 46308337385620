import React from 'react'

import SpinnerSvg from './SpinnerSvg'
import styles from './spinner.module.scss'
import classNames from 'classnames'

interface ISpinnerProps {
  className?: string
}

export const Spinner: React.FC<ISpinnerProps> = ({className, ...rest}) => {
  const classes = classNames(styles.spinner, className);
  
  return (
    <div aria-label="loading" className={classes} {...rest}>
      <SpinnerSvg />
    </div>
  )
}

export default Spinner
