import React from 'react'
import styles from './SkipToContent.module.scss'

const SkipToContent = () => {
  return (
    <a href="#skip-content" className={styles.skipToContent}>
      Skip to main content
    </a>
  )
}

export default SkipToContent
