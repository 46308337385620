import React, { type FC } from 'react'
import { footerAboutLinks } from '../../../_content/footerAboutLinks'
import { footerAdviceGuidanceLinks } from '../../../_content/footerAdviceGuidanceLinks'
import { footerContactLinks } from '../../../_content/footerContactLinks'
import { Logo } from '../../atoms/Logo'
import { CYCSLink } from '../../atoms/CYCSLink'
import styles from './Footer.module.scss'

export const Footer: FC = () => {
  const logEvent = (title: string) => {
    gtag('event', 'click', {
      event_category: 'FooterLink',
      event_label: title,
    })
  }

  return (
    // biome-ignore lint/a11y/useSemanticElements: footer with custom role
    <footer data-cy="footer" className={styles.footer} role="contentinfo">
      <h2 className="visually-hidden">Site Footer</h2>
      <div className={styles.footerContent}>
        <div className={styles.footerLeft}>
          <CYCSLink
            variant="white"
            target="_blank"
            href="https://ncsc.gov.uk"
            rel="noreferrer"
            className={styles.footerLogo}
          >
            <Logo
              id="ncsc_logo_footer"
              fill="#fff"
              testId="ncsc-logo-footer"
              ariaTitle="National Cyber Security Center Homepage - (opens in a new tab)"
            />
          </CYCSLink>
        </div>
        <div className={styles.footerRight}>
          <div>
            <h3 className={styles.footerHeader}>
              {footerAdviceGuidanceLinks.heading}
            </h3>
            <ul data-cy="footer-links">
              {footerAdviceGuidanceLinks.items.map((item, key) => (
                <li key={key}>
                  <CYCSLink
                    target="_blank"
                    underline={false}
                    className={styles.footerLink}
                    data-cy={item.testid}
                    href={item.url}
                    variant="white"
                    rel="noreferrer"
                    onClick={() => {
                      if (typeof item.title === 'string') {
                        logEvent(item.title)
                      }
                    }}
                  >
                    {item.title}
                  </CYCSLink>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className={styles.footerHeader}>
              {footerContactLinks.heading}
            </h3>
            <ul data-cy="footer-links">
              {footerContactLinks.items.map((item, key) => (
                <li key={key}>
                  <CYCSLink
                    target="_blank"
                    underline={false}
                    className={styles.footerLink}
                    data-cy={item.testid}
                    href={item.url}
                    variant="white"
                    rel="noreferrer"
                    onClick={() => {
                      if (typeof item.title === 'string') {
                        logEvent(item.title)
                      }
                    }}
                  >
                    {item.title}
                  </CYCSLink>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className={styles.footerHeader}>{footerAboutLinks.heading}</h3>
            <ul data-cy="footer-links">
              {footerAboutLinks.items.map((item, key) => (
                <li key={key}>
                  <CYCSLink
                    target="_blank"
                    underline={false}
                    className={styles.footerLink}
                    data-cy={item.testid}
                    href={item.url}
                    variant="white"
                    rel="noreferrer"
                    onClick={() => {
                      if (typeof item.title === 'string') {
                        logEvent(item.title)
                      }
                    }}
                  >
                    {item.title}
                  </CYCSLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
