// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.llgGi0OsCatfVVMaqOWI{text-decoration:underline;text-decoration-thickness:max(1px,.0625rem);text-underline-offset:.1578em;font-size:1rem;line-height:1.25;display:block;padding:var(--s-3) var(--s-4) var(--s-2)}.llgGi0OsCatfVVMaqOWI:not(:active):not(:focus){position:absolute !important;width:1px !important;height:1px !important;margin:0 !important;padding:0 !important;overflow:hidden !important;clip:rect(0 0 0 0) !important;clip-path:inset(50%) !important;border:0 !important;white-space:nowrap !important;user-select:none}.llgGi0OsCatfVVMaqOWI:focus{outline:3px solid var(--primary-focus-bg-color);outline-offset:0;background-color:var(--primary-focus-bg-color);color:var(--primary-focus-color)}.llgGi0OsCatfVVMaqOWI:active{color:var(--primary-focus-color)}`, "",{"version":3,"sources":["webpack://./src/components/atoms/SkipToContent/SkipToContent.module.scss"],"names":[],"mappings":"AAAA,sBACI,yBAAA,CACA,2CAAA,CACA,6BAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,wCAAA,CAEA,+CACI,4BAAA,CACA,oBAAA,CACA,qBAAA,CACA,mBAAA,CACA,oBAAA,CACA,0BAAA,CACA,6BAAA,CACA,+BAAA,CACA,mBAAA,CACA,6BAAA,CACA,gBAAA,CAGJ,4BACI,+CAAA,CACA,gBAAA,CACA,8CAAA,CACA,gCAAA,CAGJ,6BACI,gCAAA","sourcesContent":[".skipToContent{text-decoration:underline;text-decoration-thickness:max(1px,.0625rem);text-underline-offset:.1578em;font-size:1rem;line-height:1.25;display:block;padding:var(--s-3) var(--s-4) var(--s-2)}.skipToContent:not(:active):not(:focus){position:absolute !important;width:1px !important;height:1px !important;margin:0 !important;padding:0 !important;overflow:hidden !important;clip:rect(0 0 0 0) !important;clip-path:inset(50%) !important;border:0 !important;white-space:nowrap !important;user-select:none}.skipToContent:focus{outline:3px solid var(--primary-focus-bg-color);outline-offset:0;background-color:var(--primary-focus-bg-color);color:var(--primary-focus-color)}.skipToContent:active{color:var(--primary-focus-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skipToContent": `llgGi0OsCatfVVMaqOWI`
};
export default ___CSS_LOADER_EXPORT___;
