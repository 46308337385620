import React, { type FC } from 'react'
import { Logo } from '../../atoms/Logo'
import styles from './Header.module.scss'
import { CYCSButton } from '../../atoms/CYCSButton'
import { CYCSLink } from '../../atoms/CYCSLink'

export const Header: FC = () => {
  return (
    <header
      data-cy="header"
      className={styles.header}
      data-module="govuk-header"
    >
      <div className={styles.headerContent}>
        <div className={styles.leftContent}>
          <CYCSLink href="/">
            <Logo
              testId="ncsc_logo_header"
              id="ncsc_logo_header"
              fill="#fff"
              ariaTitle="National Cyber Security Center - Check Your Cyber Security Homepage"
              className={styles.logo}
            />
          </CYCSLink>
        </div>
        <div className={styles.rightContent}>
          <CYCSButton
            href="https://ncsc.gov.uk"
            id="service_link_desktop"
            target="_blank"
            rel="noreferrer"
            className="medium-only"
            variant="white"
          >
            go to ncsc.gov.uk
          </CYCSButton>
          <CYCSButton
            variant="white"
            href="https://ncsc.gov.uk"
            target="_blank"
            rel="noreferrer"
            className="small-only"
          >
            ncsc.gov.uk
          </CYCSButton>
        </div>
      </div>
    </header>
  )
}

export default Header
