// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.j1tCoKWFm2BlD4yqmhSJ{--bg-color: #F3F2F1;width:100%;background:var(--bg-color);padding:var(--gutter) var(--sm-margin);box-shadow:0 20px 40px 30px rgba(0,0,0,.5);display:flex;flex-direction:column;justify-content:flex-start;gap:var(--s-5)}.lLvzXP99FxSeOmfTSxTg{font-size:var(--text-h-lg-fs);line-height:var(--text-h-lg-lh);font-weight:var(--text-h-lg-fw)}.YsFRrndkN88ZyIbix5Hw{display:flex;gap:var(--s-4)}`, "",{"version":3,"sources":["webpack://./src/components/organisms/CookieBanner/CookieBanner.module.scss"],"names":[],"mappings":"AAAA,sBACI,mBAAA,CAEA,UAAA,CACA,0BAAA,CAEA,sCAAA,CAEA,0CAAA,CAEA,YAAA,CACA,qBAAA,CACA,0BAAA,CACA,cAAA,CAGJ,sBACI,6BAAA,CACA,+BAAA,CACA,+BAAA,CAGJ,sBACI,YAAA,CACA,cAAA","sourcesContent":[".cookieBanner{--bg-color: #F3F2F1;width:100%;background:var(--bg-color);padding:var(--gutter) var(--sm-margin);box-shadow:0 20px 40px 30px rgba(0,0,0,.5);display:flex;flex-direction:column;justify-content:flex-start;gap:var(--s-5)}.cookieBannerHeading{font-size:var(--text-h-lg-fs);line-height:var(--text-h-lg-lh);font-weight:var(--text-h-lg-fw)}.cookieBannerButtons{display:flex;gap:var(--s-4)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cookieBanner": `j1tCoKWFm2BlD4yqmhSJ`,
	"cookieBannerHeading": `lLvzXP99FxSeOmfTSxTg`,
	"cookieBannerButtons": `YsFRrndkN88ZyIbix5Hw`
};
export default ___CSS_LOADER_EXPORT___;
